import axios from "axios"
import catchError from "@/components/axios-catch"
import Swal from "sweetalert2"
import { getUser } from "@/components/user"

export default class {

    static headers = {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
        'Authorization': window.localStorage.getItem('token')
    }

    static user = getUser()

    static api = process.env.VUE_APP_API

    static request(url, method, payload, messageSuccess = null) {
        return axios.request({
            url: this.api + url,
            method: method,
            data: payload,
            headers: this.getHeaders(),
        })
            .then(response => {
                if (messageSuccess) Swal.fire("Sucesso", messageSuccess, "success")
                return response.data
            })
            .catch(error => {
                catchError(error)
                throw error
            })
    }

    static get(url, params = {}, messageSuccess = null) {
        params.polo_selecionado = this.user.polo.id
        return axios
            .get(this.api + url,
                {
                    headers: this.getHeaders(),
                    params,
                })
            .then(response => {
                if (messageSuccess) Swal.fire("Sucesso", messageSuccess, "success")
                return response.data
            })
            .catch(error => {
                catchError(error)
                throw error
            })
    }

    static post(url, payload = {}, messageSuccess = null) {
        payload.polo_selecionado = this.user.polo.id
        return axios
            .post(
                this.api + url,
                payload,
                { headers: this.getHeaders() }
            )
            .then(response => {
                if (messageSuccess) Swal.fire("Sucesso", messageSuccess, "success")
                return response.data
            })
            .catch(error => {
                catchError(error)
                throw error
            })
    }

    static put(url, payload = {}, messageSuccess = null) {
        payload.polo_selecionado = this.user.polo.id
        return axios
            .put(
                this.api + url,
                payload,
                { headers: this.getHeaders() }
            )
            .then(response => {
                if (messageSuccess) Swal.fire("Sucesso", messageSuccess, "success")
                return response.data
            })
            .catch(error => {
                catchError(error)
                throw error
            })
    }

    static delete(url, messageSuccess = null, params = {}) {
        params.polo_selecionado = this.user.polo.id
        return axios
            .delete(
                this.api + url,
                { headers: this.getHeaders(), params }
            )
            .then(response => {
                if (messageSuccess) Swal.fire("Sucesso", messageSuccess, "success")
                return response.data
            })
            .catch(error => {
                catchError(error)
                throw error
            })
    }
    static getHeaders() {
        return {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'Authorization': window.localStorage.getItem('token')
        }
    }
}